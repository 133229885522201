export const DATE_TIME_FORMAT = "DD-MM-YYYY h:mm a";
export const DATE_FORMAT = "DD-MM-YYYY";
export const Action = {
  EDIT: "edit",
  VIEW: "view",
  DELETE: "delete",
  TOGGLE: "toggle",
  RESET_PASSWORD: "reset_password",
  FEATURED_SETTING_ACTION: "FEATURED_SETTING_ACTION",
};
export const CanPerformAction = {
  CAN_ADD: "CAN_ADD",
  CAN_EDIT: "CAN_EDIT",
  CAN_VIEW: "CAN_VIEW",
  CAN_DELETE: "CAN_DELETE",
  CAN_EXPORT: "CAN_EXPORT",
};

export const spinAnimation = {
  WebkitAnimation: "spin 3s infinite linear",
  "@-webkit-keyframes spin": {
    "0%": { WebkitTransform: "rotate(0deg)" },
    "100%": { WebkitTransform: "rotate(360deg)" },
  },
};

export const Models = {
  DASHBOARD: "dashboard",
  BRAND: "brand",
  BANNER: "banner",
  BADGE: "badge",
  CLIENT: "client",
  CATEGORY: "category",
  COMMENT: "comment",
  DEAL: "deal",
  EMPLOYEE: "employee",
  REPORT: "report",
  RESOURCE: "resource",
  ROLE: "role",
  STORE: "store",
  USER: "user",
  SEARCH_LOG: "searchLog",
  FACEBOOK_PAGE: "facebookPage",
  EMAIL_HISTORY: "emailHistory",
  COUNTRY: "country",
  NOTIFICATION: "notification",
  REWARD_RULE: "rewardRule",
  REWARD: "reward",
  REWARD_VALIDATION_LOG: "rewardValidationLog",
  REDEEM_REWARD: "redeemReward",
  DEAL_ALERT: "dealAlert",
  STORE_REVIEW: "storeReview",
};

export const MONTHS = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const NotificationActions = {
  NEW_DEAL: "new_deal",
  NEW_DEAL_REPORT: "new_deal_report",
  NEW_REWARD_REDEEM: "new_reward_redeem",
};

export const RedeemRewardStatuses = {
  PENDING: "pending",
  ACCEPTED: "accepted",
  DECLINED: "declined",
};

export const DealType = {
  DISCOUNT: "discount",
  BUY_X_GET_X: "buy_x_get_x",
  COUPON: "coupon",
  OFFER: "offer",
};

export const RoutePathToToggleMap = {
  "/clients": "client",
  "/categories": "category",
  "/badges": "badge",
  "/banners": "banner",
  "/brands": "brand",
  "/comments": "comment",
  "/countries": "country",
  "/deals": "deal",
  "/deal-alerts": "dealAlert",
  "/employees": "employee",
  "/reports": "report",
  "/roles": "role",
  "/resources": "resource",
  "/stores": "store",
  "/store-reviews": "storeReview",
  "/users": "user",
  "/search-logs": "searchLog",
  "/facebook-pages": "facebookPage",
  "/email-histories": "emailHistory",
  "/notifications": "notification",
  "/rewards": "reward",
  "/reward-rules": "rewardRule",
  "/reward-validation-logs": "rewardRuleValidationLog",
  "/redeem-rewards": "redeemReward",
};
