import { Stack, Button, Box, Tab } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { DmBox } from "../../components";
import { CountryView, DealStoreView } from "../../components/views";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Authenticate } from "../../hoc/Authenticate";
import { Action, Models } from "../../const";
import EditIcon from "@mui/icons-material/Edit";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export const ViewStorePage = () => {
  const { slug } = useParams();
  const stores = useSelector((state) => state.store.stores);
  const foundStore = stores.find((eachStore) => eachStore.slug === slug);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query") ?? "";
  const countryId = searchParams.get("countryId") ?? "";
  const limit = searchParams.get("limit") ?? 1;
  const page = searchParams.get("page") ?? 10;
  const queryParams = new URLSearchParams();
  queryParams.append("query", query);
  queryParams.append("countryId", countryId);
  queryParams.append("page", page);
  queryParams.append("limit", limit);
  const [value, setValue] = React.useState("1");
  const TabValue = {
    DETAILS: "1",
    COUNTRY: "2",
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <DmBox>
      <Stack direction="row" spacing={5} mb="1rem">
        <Button
          variant="text"
          color="secondary"
          onClick={(e) => navigate(`/stores?${queryParams.toString()}`)}
          startIcon={<ArrowBackIcon />}
        >
          Back
        </Button>
        <Authenticate model={Models.STORE} action={Action.EDIT}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() =>
              navigate(
                `/stores/edit/${foundStore.slug}?${queryParams.toString()}`
              )
            }
            endIcon={<EditIcon />}
          >
            Edit
          </Button>
        </Authenticate>
      </Stack>
      <TabContext value={value} width="100%">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Details" value={TabValue.DETAILS} />
            <Tab label="Country" value={TabValue.COUNTRY} />
          </TabList>
        </Box>
        <TabPanel value={TabValue.DETAILS} sx={{ p: "1rem 0px" }}>
          <DealStoreView dealStore={foundStore} />
        </TabPanel>
        <TabPanel value={TabValue.COUNTRY} sx={{ p: "1rem 0px" }}>
          <CountryView country={foundStore.country} />
        </TabPanel>
      </TabContext>
    </DmBox>
  );
};
