import { api } from "../config";

export const StoreService = {
  getStores: async (query, countryId, page, limit) => {
    try {
      const { data } = await api.get(
        `api/admin/stores/?query=${query}&country=${countryId}&page=${page}&limit=${limit}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  getStore: async (slug) => {
    try {
      const { data } = await api.get(`api/admin/stores/${slug}`);
      return data;
    } catch (error) {
      throw error;
    }
  },
  createStore: async (payload) => {
    try {
      const { data } = await api.post("api/admin/stores/", payload, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  },
  updateStore: async (slug, payload) => {
    try {
      const { data } = await api.put(
        `api/admin/stores/update/${slug}`,
        payload,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  },
  deleteStore: async (payload) => {
    try {
      const { data } = await api.post("api/admin/stores/delete", payload);
      return data;
    } catch (error) {
      throw error;
    }
  },
};
