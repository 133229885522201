import { call, put, takeEvery } from "redux-saga/effects";
import { toast } from "react-toastify";
import { NotifyUtil } from "../../utils";
import { StoreService } from "../../services";
import {
  CREATE_STORE_REQUEST,
  DELETE_STORE_REQUEST,
  FETCH_STORES_REQUEST,
  FETCH_STORE_REQUEST,
  UPDATE_STORE_REQUEST,
} from "./storeActionType";
import {
  createStoreFailure,
  createStoreSuccess,
  deleteStoreFailure,
  deleteStoreSuccess,
  fetchStoreFailure,
  fetchStoresFailure,
  fetchStoresSuccess,
  fetchStoreSuccess,
  updateStoreFailure,
  updateStoreSuccess,
} from "./storeAction";

function* fetchStoresSaga(action) {
  try {
    const { query, countryId, page, limit } = action;
    const { data } = yield call(
      StoreService.getStores,
      query,
      countryId,
      page,
      limit
    );
    yield put(fetchStoresSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchStoresFailure(error));
  }
}

function* fetchStoreSaga(action) {
  try {
    const { data } = yield call(StoreService.getStore, action.slug);
    yield put(fetchStoreSuccess(data));
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(fetchStoreFailure(error));
  }
}

function* createStoreSaga(action) {
  try {
    const {
      meta: { onSuccess },
    } = action;
    const { data } = yield call(StoreService.createStore, action.payload);
    yield put(createStoreSuccess(data));
    toast.success("Successfully created");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(createStoreFailure(error));
  }
}

function* updateStoreSaga(action) {
  try {
    const {
      slug,
      payload,
      meta: { onSuccess },
    } = action;
    yield call(StoreService.updateStore, slug, payload);
    yield put(updateStoreSuccess());
    toast.success("Successfully updated");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(updateStoreFailure(error));
  }
}

function* deleteStoreSaga(action) {
  try {
    const {
      payload,
      meta: { onSuccess },
    } = action;
    yield call(StoreService.deleteStore, payload);
    yield put(deleteStoreSuccess());
    toast.success("Successfully deleted");
    onSuccess();
  } catch (error) {
    toast.error(NotifyUtil.errorMessage(error));
    yield put(deleteStoreFailure(error));
  }
}

export function* storeWatcherSaga() {
  yield takeEvery(FETCH_STORES_REQUEST, fetchStoresSaga);
  yield takeEvery(FETCH_STORE_REQUEST, fetchStoreSaga);
  yield takeEvery(CREATE_STORE_REQUEST, createStoreSaga);
  yield takeEvery(UPDATE_STORE_REQUEST, updateStoreSaga);
  yield takeEvery(DELETE_STORE_REQUEST, deleteStoreSaga);
}
