import React, { useEffect, useState, useCallback } from "react";
import { Button, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { ColumnOption, DmTable, ColumnTitle, DmBox } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { deleteSearchLogRequest, fetchSearchLogsRequest } from "../../store";
import moment from "moment";
import {
  Action,
  CanPerformAction,
  DATE_TIME_FORMAT,
  Models,
} from "../../const";
import { ConfirmModal, DmModal } from "../../components/Modal";
import { useToggle } from "../../hooks";
import { useNavigate } from "react-router-dom";
import { searchQuerySelector } from "../../store/selector";
import _ from "lodash";
import { Authenticate } from "../../hoc/Authenticate";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";

export const SearchLogListPage = () => {
  const dispatch = useDispatch();
  const searchLogState = useSelector((state) => state.searchLog);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRowId, setActiveRowId] = useState(); // this is used only for hide and show ... options
  const [offset, setOffset] = useState({ limit: 10, skip: 0 });
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useToggle();
  const searchQuery = useSelector(searchQuerySelector);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
  }, []);

  const handleOptionClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onMouseEnterRowHandler = (row) => {
    setActiveRowId(row.id);
  };
  const onMouseLeaveRowHandler = () => {
    setAnchorEl(null);
    setActiveRowId(null);
  };

  const onSuccessfullyDelete = () => {
    setOffset({ limit: offset.limit, skip: offset.skip });
  };

  const onDelete = () => {
    dispatch(
      deleteSearchLogRequest(
        { searchLogIds: _.map(selectedRows, "id") },
        onSuccessfullyDelete
      )
    );
    setOpenModal(); // close modal
    setToggleCleared(!toggleCleared); // clear all selection
  };

  const onClickMenuItem = (action, row) => {
    switch (action) {
      case Action.DELETE:
        setSelectedRows([row]);
        setOpenModal();
        break;
      case Action.VIEW:
        navigate(`/search-logs/details/${row.id}`);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(fetchSearchLogsRequest(offset, searchQuery));
  }, [dispatch, offset, searchQuery]);

  const onChangeRowsPerPage = (currentRowsPerPage, currentPage) => {
    const skip = getSkip(currentPage);
    setOffset({ limit: currentRowsPerPage, skip: skip });
  };

  const onChangePage = (page) => {
    const skip = getSkip(page);
    setOffset({ ...offset, skip });
  };

  const getSkip = (page) => {
    const skip = page - 1 > 0 ? page - 1 : 0;
    const calculateSkip = offset.limit * skip;
    return calculateSkip;
  };

  const columns = [
    {
      name: <ColumnTitle title={"Id"} />,
      selector: (row) => (
        <Button
          variant="link"
          onClick={() => navigate(`/search-logs/details/${row.id}`)}
        >
          {row.id}
        </Button>
      ),
      minWidth: "210px",
    },
    {
      name: <ColumnTitle title={"Query"} />,
      selector: (row) => row.query,
      minWidth: "310px",
    },
    {
      name: <ColumnTitle title={"Result"} />,
      selector: (row) => row.resultCount,
    },
    {
      name: <ColumnTitle title={"Ip Address"} />,
      selector: (row) => row.ipAddress,
      minWidth: "210px",
    },
    {
      name: <ColumnTitle title={"Name"} />,
      selector: (row) => row?.user?.name,
    },
    {
      name: <ColumnTitle title={"Email"} />,
      selector: (row) => row?.user?.email,
      minWidth: "210px",
    },
    {
      name: <ColumnTitle title={"Phone"} />,
      selector: (row) => row?.user?.mobileNumber,
    },
    {
      name: <ColumnTitle title={"Created"} />,
      selector: (row) => moment(row.createdAt).format(DATE_TIME_FORMAT),
      minWidth: "210px",
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Updated"} />,
      selector: (row) => moment(row.updatedAt).format(DATE_TIME_FORMAT),
      minWidth: "210px",
      sortable: true,
    },
    {
      name: <ColumnTitle title={""} />,
      cell: (row) => (
        <>
          {activeRowId === row.id && (
            <ColumnOption
              row={row}
              open={open}
              anchorEl={anchorEl}
              handleClick={handleOptionClick}
              handleClose={handleOptionClose}
              onClickMenuItem={onClickMenuItem}
            >
              <Authenticate
                model={Models.SEARCH_LOG}
                action={CanPerformAction.CAN_DELETE}
              >
                <MenuItem onClick={() => onClickMenuItem(Action.DELETE, row)}>
                  <ListItemIcon>
                    <DeleteIcon />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>
              </Authenticate>
              <MenuItem onClick={() => onClickMenuItem(Action.VIEW, row)}>
                <ListItemIcon>
                  <VisibilityIcon />
                </ListItemIcon>
                <ListItemText>View</ListItemText>
              </MenuItem>
            </ColumnOption>
          )}
        </>
      ),
    },
  ];

  return (
    <DmBox>
      <DmTable
        columns={columns}
        data={searchLogState.searchLogs}
        title={"Search Log List"}
        pending={searchLogState.loading}
        totalRow={searchLogState.totalSearchLogs}
        onRowMouseEnter={onMouseEnterRowHandler}
        onRowMouseLeave={onMouseLeaveRowHandler}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        contextActions={
          <Authenticate
            model={Models.SEARCH_LOG}
            action={CanPerformAction.CAN_DELETE}
          >
            <Button
              onClick={setOpenModal}
              sx={{
                backgroundColor: "red",
                color: "white",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
            >
              Delete
            </Button>
          </Authenticate>
        }
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
      />
      <DmModal open={openModal} closeModal={setOpenModal}>
        <ConfirmModal
          title={
            selectedRows.length > 1
              ? `Are you sure want to delete ${selectedRows.length} search logs ?`
              : `Are you sure you want to delete this search log ?`
          }
          subTitle="This action cannot be undone"
          btnConfirmText={"Delete"}
          btnCancelText="Cancel"
          onCancel={setOpenModal}
          onConfirm={onDelete}
        />
      </DmModal>
    </DmBox>
  );
};
