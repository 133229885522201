import {
  CREATE_STORE_FAILURE,
  CREATE_STORE_REQUEST,
  CREATE_STORE_SUCCESS,
  DELETE_STORE_FAILURE,
  DELETE_STORE_REQUEST,
  DELETE_STORE_SUCCESS,
  FETCH_STORES_FAILURE,
  FETCH_STORES_REQUEST,
  FETCH_STORES_SUCCESS,
  FETCH_STORE_FAILURE,
  FETCH_STORE_REQUEST,
  FETCH_STORE_SUCCESS,
  UPDATE_STORE_FAILURE,
  UPDATE_STORE_REQUEST,
  UPDATE_STORE_SUCCESS,
} from "./storeActionType";

export const fetchStoresRequest = (query, countryId, page, limit) => {
  return {
    type: FETCH_STORES_REQUEST,
    query,
    countryId,
    page,
    limit,
  };
};

export const fetchStoresSuccess = (data) => {
  return {
    type: FETCH_STORES_SUCCESS,
    payload: data,
  };
};

export const fetchStoresFailure = (error) => {
  return {
    type: FETCH_STORES_FAILURE,
    error,
  };
};

export const fetchStoreRequest = (slug) => {
  return {
    type: FETCH_STORE_REQUEST,
    slug,
  };
};
export const fetchStoreSuccess = (data) => {
  return {
    type: FETCH_STORE_SUCCESS,
    payload: data,
  };
};
export const fetchStoreFailure = (error) => {
  return {
    type: FETCH_STORE_FAILURE,
    error,
  };
};

export const createStoreRequest = (payload, onSuccess) => {
  return {
    type: CREATE_STORE_REQUEST,
    payload,
    meta: {
      onSuccess,
    },
  };
};
export const createStoreSuccess = (data) => {
  return {
    type: CREATE_STORE_SUCCESS,
    payload: data,
  };
};
export const createStoreFailure = (error) => {
  return {
    type: CREATE_STORE_FAILURE,
    error,
  };
};

export const updateStoreRequest = (slug, payload, onSuccess) => {
  return {
    type: UPDATE_STORE_REQUEST,
    slug,
    payload,
    meta: {
      onSuccess,
    },
  };
};

export const updateStoreSuccess = () => {
  return {
    type: UPDATE_STORE_SUCCESS,
  };
};

export const updateStoreFailure = (error) => {
  return {
    type: UPDATE_STORE_FAILURE,
    error,
  };
};

export const deleteStoreRequest = (payload, onSuccessfullyDelete) => {
  return {
    type: DELETE_STORE_REQUEST,
    payload,
    meta: {
      onSuccess: onSuccessfullyDelete,
    },
  };
};

export const deleteStoreSuccess = () => {
  return {
    type: DELETE_STORE_SUCCESS,
  };
};

export const deleteStoreFailure = (error) => {
  return {
    type: DELETE_STORE_FAILURE,
    error,
  };
};
