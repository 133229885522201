import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  IconButton,
  Box,
  Stack,
  Tooltip,
  MenuItem,
  Avatar,
  Typography,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Navbar } from "./Navbar";
import { useToggle } from "../hooks";
import { NotificationBar } from "./notificationBar";
import { employeeLogOutRequest } from "../store";
import { useDispatch, useSelector } from "react-redux";
import { Search } from "./Search";
import { PopupMenu } from "./PopupMenu";
import { UrlUtil } from "../utils";
import { loggedEmployeeSelector } from "../store/selector";

const drawerWidth = 280;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
  backgroundColor: "rgb(35, 48, 68)",
  color: "rgb(238, 238, 238)",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
  backgroundColor: "rgb(35, 48, 68)",
  color: "rgb(238, 238, 238)",
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
      },
    },
  ],
}));

export const DmDrawer = () => {
  const [open, setOpen] = useToggle(true);
  const iconColor = "rgb(158, 158, 158)";
  const accountRef = React.useRef();
  const [openAccountMenu, setToggleAccountMenu] = useToggle();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const loggedEmployee = useSelector(loggedEmployeeSelector);

  const handleAccountMenuClose = (event) => {
    if (accountRef.current && accountRef.current.contains(event.target)) {
      return;
    }
    setToggleAccountMenu();
    switch (event.target.innerText) {
      case "Logout":
        dispatch(employeeLogOutRequest());
        break;
      case "Profile":
        navigation("/profile");
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={setOpen}>
            {!open ? (
              <ChevronRightIcon
                sx={{ color: (theme) => theme.palette.icon.default }}
              />
            ) : (
              <ChevronLeftIcon
                sx={{ color: (theme) => theme.palette.icon.default }}
              />
            )}
          </IconButton>
        </DrawerHeader>
        <Stack alignItems="center" marginTop="1rem" spacing={2}>
          <Avatar
            alt="admin"
            src={UrlUtil.getFullImagePath(loggedEmployee.image.path)}
            sx={{
              width: open ? "80px" : "50px",
              height: open ? "80px" : "50px",
            }}
          >
            {loggedEmployee.firstName.substring(0, 2)}
          </Avatar>
          {open && (
            <Typography>{`${loggedEmployee.firstName} ${loggedEmployee.lastName}`}</Typography>
          )}
        </Stack>
        <Navbar isDrawerOpen={open} />
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, overflowY: "auto" }}>
        <Stack direction="row" padding="1rem" justifyContent="space-between">
          <Box sx={{ width: "30%" }}>
            <Search variant="standard" placeholder="Search topics..." />
          </Box>
          <Stack direction="row" spacing={1}>
            <NotificationBar />
            <Tooltip title="Account">
              <IconButton ref={accountRef} onClick={setToggleAccountMenu}>
                <PowerSettingsNewIcon sx={{ color: iconColor }} />
              </IconButton>
            </Tooltip>
            <PopupMenu
              anchorRef={accountRef}
              open={openAccountMenu}
              handleClose={handleAccountMenuClose}
              sx={{
                zIndex: 1,
                boxShadow: "rgba(0, 0, 0, 0.05) 0px 0px 0px 1px",
              }}
            >
              <MenuItem onClick={handleAccountMenuClose}>Profile</MenuItem>
              <MenuItem onClick={handleAccountMenuClose}>Logout</MenuItem>
            </PopupMenu>
          </Stack>
        </Stack>
        <Outlet />
      </Box>
    </Box>
  );
};
