import { Stack, Button, InputAdornment, Box, Typography } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { Form, Field } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Input, InputSwitch, InputFile } from "../";
import {
  createStoreRequest,
  fetchAllCountriesRequest,
  updateStoreRequest,
} from "../../store";
import { ArrayUtil, ValidationUtil } from "../../utils";
import { AutoCompleteSelect } from "../Input";

export const StoreForm = ({ store }) => {
  const countryState = useSelector((state) => state.country);
  const countryOptions = ArrayUtil.getOptions(countryState?.countries);

  const [isActive, setIsActive] = useState(true);
  const [files, setFiles] = useState(store?.image?.path ? [store.image] : []);
  const [selectedCountry, setSelectedCountry] = useState(
    store?.country?.id && {
      value: store?.country?.id,
      label: store?.country?.displayName ?? store?.country?.name,
    }
  );
  const storeAddresses = ArrayUtil.getCoordinateAndAddresses(
    store?.addresses,
    store?.location?.coordinates
  );

  const [addresses, setAddresses] = useState(storeAddresses);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query") ?? "";
  const countryId = searchParams.get("countryId") ?? "";
  const page = searchParams.get("page") ?? 1;
  const limit = searchParams.get("limit") ?? 10;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAllCountriesRequest());
  }, [dispatch]);

  const removeAddress = (removedIndex, form) => {
    const filterAddress = addresses.filter(
      (item, index) => index !== removedIndex
    );
    setAddresses(filterAddress);
    const currentAddresses = form.getState().values.addresses || [];
    const updatedAddresses = currentAddresses.filter(
      (_, i) => i !== removedIndex
    );

    form.change("addresses", updatedAddresses); // Update the addresses in form state
  };

  const addAddress = (form) => {
    setAddresses((prev) => [...prev, { address: "", coordinates: [] }]);
    // Add a new field dynamically to the final form's state
    form.change("addresses", [
      ...(form.getState().values.addresses || []),
      { address: "", longitude: "", latitude: "" }, // matching final form field structure
    ]);
  };

  const handleAddresses = (targetIndex, propertyType, value) => {
    const updatedAddresses = addresses.map((item, index) => {
      let coordinates = [];
      if (targetIndex === index) {
        if (propertyType === "longitude") {
          coordinates = [value, item?.coordinates[1] ?? ""];
          return {
            ...item,
            coordinates,
          };
        } else if (propertyType === "latitude") {
          coordinates = [item?.coordinates[0] ?? "", value];
          return {
            ...item,
            coordinates,
          };
        }
        return {
          ...item,
          address: value.replaceAll(",", "-"),
        };
      }
      return item;
    });
    setAddresses(updatedAddresses);
  };

  const onSubmit = async (values) => {
    const {
      name,
      displayName,
      websiteLink,
      description,
      isActive,
      country,
      addresses: formAddresses,
    } = values;
    let formData = new FormData();
    const allAddresses =
      formAddresses?.map(({ address, ...rest }) =>
        address.replaceAll(",", "-")
      ) ?? [];
    const coordinates =
      formAddresses?.map(({ address, ...rest }) => rest) ?? [];

    const coordinatesValues = [];
    for (const eachCoordinate of coordinates) {
      coordinatesValues.push(eachCoordinate.longitude);
      coordinatesValues.push(eachCoordinate.latitude);
    }
    formData.append("name", name);
    formData.append("display_name", displayName);
    formData.append("website_link", websiteLink);
    formData.append("description", description);
    formData.append("addresses", allAddresses);
    formData.append("coordinates", coordinatesValues);
    formData.append("is_active", isActive);
    formData.append("country", country.value);
    formData.append(
      "image",
      files[0]?.size ? files[0] : JSON.stringify(files[0])
    );
    store?.id
      ? dispatch(updateStoreRequest(store.slug, formData, goBack))
      : dispatch(createStoreRequest(formData, goBack));
  };

  const goBack = () => {
    const queryParams = new URLSearchParams();
    queryParams.append("query", query);
    queryParams.append("countryId", countryId);
    queryParams.append("limit", limit);
    queryParams.append("page", page);
    navigate(`/stores?${queryParams.toString()}`);
  };

  const initialAddresses = useMemo(() => {
    const addressAndCoordinates = ArrayUtil.getCoordinateAndAddresses(
      store?.addresses,
      store?.location?.coordinates
    );
    return addressAndCoordinates.map(({ address, coordinates }) => {
      return {
        address: address ?? "",
        longitude: coordinates?.length > 0 ? coordinates[0] : "",
        latitude: coordinates?.length > 0 ? coordinates[1] : "",
      };
    });
  }, [store]);

  const initialValues = store
    ? {
        name: store.name,
        displayName: store.displayName,
        websiteLink: store.websiteLink,
        description: store.description,
        country: selectedCountry,
        addresses: initialAddresses,
        isActive: store.isActive,
      }
    : {};

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      render={({ handleSubmit, form, submitting, errors }) => (
        <form
          onSubmit={(event) => {
            handleSubmit(event);
            if (Object.keys(errors).length === 0) form.reset();
          }}
          noValidate
          autoComplete="off"
        >
          <Stack alignItems="center">
            <Typography variant="h4">
              {store ? `Edit ${store.name}` : "Add new store"}
            </Typography>
            <Stack spacing={2} maxWidth="750px" width="60%" mt={"1rem"}>
              <Field
                name="name"
                component={Input}
                label="Name"
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="displayName"
                component={Input}
                label="Display Name"
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="websiteLink"
                component={Input}
                label="Website Link"
                validate={ValidationUtil.composeValidators(
                  ValidationUtil.requiredValidator,
                  ValidationUtil.isValidHttpUrl
                )}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">http</InputAdornment>
                  ),
                }}
              />
              <Field
                name="country"
                component={AutoCompleteSelect}
                label="Country"
                options={countryOptions}
                handleOnChange={(value) => setSelectedCountry(value)}
                value={selectedCountry}
                defaultValue={selectedCountry}
                validate={ValidationUtil.requiredValidator}
              />
              <Field
                name="description"
                component={Input}
                label="Description"
                type="textarea"
                multiline
                inputProps={{
                  sx: {
                    minHeight: "150px",
                  },
                }}
                validate={ValidationUtil.requiredValidator}
              />
              <Box>
                <Field
                  name="isActive"
                  checked={isActive}
                  defaultValue={isActive}
                  component={InputSwitch}
                  handleOnChange={(value) => setIsActive(value)}
                  type="checkbox"
                  label="Is Active"
                  labelPlacement="start"
                  sx={{ ml: "0px" }}
                />
              </Box>
              <Field
                name="image"
                component={InputFile}
                files={files}
                key={files.length > 0 ? 1 : 0} // changed key to re-render validate function
                onChangeHandler={(value) => {
                  setFiles(value);
                }}
                validate={
                  store?.image
                    ? ValidationUtil.composeValidators(
                        () => ValidationUtil.requiredValidator(files),
                        () =>
                          ValidationUtil.numberOfFilesValidator(files ?? [], 1),
                        () => ValidationUtil.fileSizeValidator(files)
                      )
                    : ValidationUtil.composeValidators(
                        ValidationUtil.requiredValidator,
                        ValidationUtil.fileSizeValidator,
                        ValidationUtil.fileExtensionValidator,
                        (files) =>
                          ValidationUtil.numberOfFilesValidator(files ?? [], 1)
                      )
                }
                sx={{
                  border: "2px dashed #5E54FF",
                  p: ".8rem",
                  marginBottom: "2rem",
                  alignItems: "center",
                }}
              />
              <Box
                sx={{
                  padding: "1rem",
                  border: (theme) => `1px solid ${theme.borderColor}`,
                }}
              >
                <Button variant="contained" onClick={() => addAddress(form)}>
                  Add Address
                </Button>
                {addresses.map((eachAddress, index) => (
                  <Stack key={index} sx={{ gap: 2, padding: "1rem" }}>
                    {eachAddress?.coordinates &&
                      eachAddress?.coordinates[0] !== 0 && (
                        <>
                          <Stack
                            sx={{
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <Typography>Address {index + 1}</Typography>
                            <Button onClick={() => removeAddress(index, form)}>
                              Remove Address
                            </Button>
                          </Stack>
                          <Field
                            name={`addresses[${index}].address`}
                            component={Input}
                            label="Address"
                            validate={ValidationUtil.requiredValidator}
                            onBlur={(e) =>
                              handleAddresses(index, "address", e.target.value)
                            }
                          />
                          <Field
                            name={`addresses[${index}].longitude`}
                            component={Input}
                            label="Longitude"
                            type="number"
                            validate={ValidationUtil.requiredValidator}
                            onBlur={(e) =>
                              handleAddresses(
                                index,
                                "longitude",
                                e.target.value
                              )
                            }
                          />
                          <Field
                            name={`addresses[${index}].latitude`}
                            component={Input}
                            label="Latitude"
                            type="number"
                            validate={ValidationUtil.requiredValidator}
                            onBlur={(e) =>
                              handleAddresses(index, "latitude", e.target.value)
                            }
                          />
                        </>
                      )}
                  </Stack>
                ))}
              </Box>
              <Stack direction="row" spacing={2} mt={"30px"}>
                <Button
                  sx={{
                    width: "20%",
                    boxShadow: "none",
                    "&:hover": {
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  type="submit"
                  disabled={submitting}
                >
                  Submit
                </Button>
                <Button
                  sx={{
                    width: "20%",
                    boxShadow: "none",
                    backgroundColor: "#03a9f4",
                    "&:hover": {
                      backgroundColor: "#0288d1",
                      boxShadow: "none",
                    },
                  }}
                  variant="contained"
                  onClick={goBack}
                >
                  Cancel
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </form>
      )}
    />
  );
};
