import {
  Box,
  Paper,
  Stack,
  Typography,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import { Form, Field } from "react-final-form";
import React from "react";
import { useToggle } from "../../hooks";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Input } from "../";
import { ValidationUtil } from "../../utils";
import { useDispatch } from "react-redux";
import { employeeLoginRequest } from "../../store";
import { useNavigate } from "react-router-dom";

export const LoginForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (values) => {
    const { email, password } = values;
    dispatch(employeeLoginRequest({ email, password }, onSuccessLoginHandler));
  };

  const onSuccessLoginHandler = () => {
    navigate("/");
  };

  const [showPassword, toggleHandler] = useToggle();

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit} noValidate autoComplete="off">
          <Box
            sx={{
              backgroundColor: "rgb(247, 249, 252)",
              width: "100%",
              height: "100vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paper
              sx={{ maxWidth: { md: "520px", xs: "90%" }, padding: "2rem" }}
            >
              <Stack alignItems="center" spacing={1} marginBottom="2rem">
                <Box
                  component="img"
                  alt="logo"
                  src="/images/logo.png"
                  sx={{
                    width: 200,
                    height: 200,
                    objectFit: "contain",
                    border: "2px solid whitesmoke",
                  }}
                ></Box>
                <Typography variant="h6">
                  Sign in to your account to continue
                </Typography>
              </Stack>
              <Stack spacing={2}>
                <Field
                  name="email"
                  component={Input}
                  label="Email"
                  InputProps={{ type: "email" }}
                  validate={ValidationUtil.composeValidators(
                    ValidationUtil.requiredValidator,
                    ValidationUtil.emailValidator
                  )}
                ></Field>
                <Field
                  name="password"
                  component={Input}
                  label="Password"
                  validate={ValidationUtil.requiredValidator}
                  InputProps={{
                    type: showPassword ? "text" : "password",
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={toggleHandler}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
              <Button
                sx={{ marginTop: "30px" }}
                fullWidth={true}
                variant="contained"
                type="submit"
              >
                Sign In
              </Button>
            </Paper>
          </Box>
        </form>
      )}
    />
  );
};
