export const UrlUtil = {
  getFullImagePath: (imagePath) => {
    return `${process.env.REACT_APP_API_DOMAIN}${imagePath}`;
  },
  getDomainDealViewUrl: (categorySlug, dealSlug, countrySlug) => {
    return `${process.env.REACT_APP_WEB_SITE_DOMAIN}categories/${categorySlug}/discount/${dealSlug}/${countrySlug}`;
  },
  getDomainStoreViewUrl: (storeSlug, countrySlug) => {
    return `${process.env.REACT_APP_WEB_SITE_DOMAIN}stores/${countrySlug}/${storeSlug}`;
  },
};
