import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
} from "@mui/material";
import {
  ColumnOption,
  DmTable,
  ColumnTitle,
  DmBox,
  StatusChip,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteStoreRequest,
  fetchAllCountriesRequest,
  fetchStoresRequest,
} from "../../store";
import moment from "moment";
import { Action, CanPerformAction, DATE_FORMAT, Models } from "../../const";
import { ConfirmModal, DmModal } from "../../components/Modal";
import { useToggle } from "../../hooks";
import { useNavigate, useSearchParams } from "react-router-dom";
import { searchQuerySelector } from "../../store/selector";
import { UrlUtil } from "../../utils";
import _ from "lodash";
import { Authenticate } from "../../hoc/Authenticate";

export const StoreListPage = () => {
  const dispatch = useDispatch();
  const countryState = useSelector((state) => state.country);
  const storeState = useSelector((state) => state.store);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeRow, setActiveRow] = useState({});
  const [activeRowId, setActiveRowId] = useState(); // this is used only for hide and show ... options
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useToggle();
  const searchQuery = useSelector(searchQuerySelector);
  const [selectedRows, setSelectedRows] = React.useState([]);
  const [toggleCleared, setToggleCleared] = React.useState(false);
  const [searchParams, setSearchParams] = useSearchParams({
    countryId: "",
    page: "1",
    limit: "10",
  });

  const countryId = searchParams.get("countryId") ?? "";
  const page = parseInt(searchParams.get("page") ?? 1);
  const limit = parseInt(searchParams.get("limit") ?? 10);

  const handleRowSelected = useCallback((state) => {
    setSelectedRows(state.selectedRows);
    if (state.selectedRows.length === 1) setActiveRow(state.selectedRows[0]);
  }, []);

  const handleOptionClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onMouseEnterRowHandler = (row) => {
    if (selectedRows.length === 0) setActiveRow(row);
    setActiveRowId(row.id);
  };
  const onMouseLeaveRowHandler = () => {
    setAnchorEl(null);
    setActiveRowId(null);
  };

  const onSuccessfullyDelete = () => {
    navigate(
      `?query=${searchQuery}&countryId=${countryId}&page=${
        page + 1
      }&limit=${limit}`
    );
  };

  const onDelete = () => {
    dispatch(
      deleteStoreRequest(
        { storeIds: _.map(selectedRows, "id") },
        onSuccessfullyDelete
      )
    );
    setOpenModal(); // close modal
    setToggleCleared(!toggleCleared); // clear all selection
  };

  const onClickMenuItem = (action, row) => {
    switch (action) {
      case Action.DELETE:
        setSelectedRows([row]);
        if (selectedRows.length === 1) setActiveRow(row);
        setOpenModal();
        break;
      case Action.EDIT:
        navigate(
          `/stores/edit/${row.slug}?query=${searchQuery}&countryId=${countryId}&page=${page}&limit=${limit}`
        );
        break;
      case Action.VIEW:
        navigate(
          `/stores/details/${row.slug}?query=${searchQuery}&countryId=${countryId}&page=${page}&limit=${limit}
          }`
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    dispatch(fetchStoresRequest(searchQuery, countryId, page - 1, limit));
  }, [dispatch, searchQuery, countryId, page, limit]);

  useEffect(() => {
    dispatch(fetchAllCountriesRequest());
  }, [dispatch]);

  const onChangeRowsPerPage = (currentRowsPerPage) => {
    if (limit !== currentRowsPerPage) {
      setSearchParams(
        (prev) => {
          prev.set("limit", currentRowsPerPage);
          return prev;
        },
        { replace: true }
      );
    }
  };

  const onChangePage = (currentPage) => {
    if (page !== currentPage) {
      setSearchParams(
        (prev) => {
          prev.set("page", currentPage);
          return prev;
        },
        { replace: true }
      );
    }
  };

  const columns = [
    {
      name: <ColumnTitle title={"Id"} />,
      selector: (row) => row.id,
      minWidth: "210px",
    },
    {
      name: <ColumnTitle title={"Name"} />,
      selector: (row) => (
        <Button
          variant="link"
          onClick={() =>
            navigate(
              `/stores/details/${row.slug}?query=${searchQuery}&countryId=${countryId}&page=${page}&limit=${limit}`
            )
          }
        >
          {row.name}
        </Button>
      ),
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Display Name"} />,
      selector: (row) => row.displayName,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Slug"} />,
      selector: (row) => row.slug,
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Website Link"} />,
      selector: (row) => (
        <Stack gap={4} direction="row">
          <Link
            href={row.websiteLink}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ textDecoration: "none" }}
          >
            Client
          </Link>
          <Link
            href={UrlUtil.getDomainStoreViewUrl(row.slug, row.country.slug)}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ textDecoration: "none" }}
          >
            Store
          </Link>
        </Stack>
      ),
    },
    {
      name: <ColumnTitle title={"Image"} />,
      maxWidth: "150px",
      selector: (row) => (
        <Box
          component="img"
          sx={{ width: "50px", height: "50px", objectFit: "contain" }}
          src={
            row.image?.path
              ? UrlUtil.getFullImagePath(row.image.path)
              : "/images/default-thumbnail-image.gif"
          }
        />
      ),
    },
    {
      name: <ColumnTitle title={"Enabled"} />,
      selector: (row) => <StatusChip status={row.isActive} />,
    },
    {
      name: <ColumnTitle title={"Created"} />,
      selector: (row) => moment(row.createdAt).format(DATE_FORMAT),
      sortable: true,
    },
    {
      name: <ColumnTitle title={"Updated"} />,
      selector: (row) => moment(row.updatedAt).format(DATE_FORMAT),
      sortable: true,
    },
    {
      name: <ColumnTitle title={""} />,
      cell: (row) => (
        <>
          {activeRowId === row.id && (
            <ColumnOption
              row={row}
              open={open}
              model={Models.STORE}
              anchorEl={anchorEl}
              handleClick={handleOptionClick}
              handleClose={handleOptionClose}
              onClickMenuItem={onClickMenuItem}
            />
          )}
        </>
      ),
    },
  ];

  return (
    <DmBox>
      <DmTable
        columns={columns}
        data={storeState.stores}
        title={"Store List"}
        pending={storeState.loading}
        totalRow={storeState.totalStores}
        paginationDefaultPage={page}
        paginationPerPage={limit}
        onRowMouseEnter={onMouseEnterRowHandler}
        onRowMouseLeave={onMouseLeaveRowHandler}
        onChangeRowsPerPage={onChangeRowsPerPage}
        onChangePage={onChangePage}
        actions={
          <Stack direction="row" spacing={2} sx={{ width: "300px" }}>
            <FormControl fullWidth size="small">
              <InputLabel>Country</InputLabel>
              <Select
                value={countryId}
                label="Country"
                onChange={(e) =>
                  setSearchParams((prev) => {
                    prev.set("countryId", e.target.value);
                    return prev;
                  })
                }
                sx={{ fontSize: ".9rem" }}
              >
                <MenuItem value={""} sx={{ fontSize: ".9rem" }}>
                  Select
                </MenuItem>
                {countryState?.countries?.map((country) => (
                  <MenuItem
                    key={country.id}
                    value={country.id}
                    sx={{ fontSize: ".9rem" }}
                  >
                    {country.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Authenticate
              model={Models.STORE}
              action={CanPerformAction.CAN_ADD}
            >
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate("/stores/add")}
              >
                Add
              </Button>
            </Authenticate>
          </Stack>
        }
        contextActions={
          <Authenticate
            model={Models.STORE}
            action={CanPerformAction.CAN_DELETE}
          >
            <Button
              onClick={setOpenModal}
              sx={{
                backgroundColor: "red",
                color: "white",
                "&:hover": {
                  backgroundColor: "red",
                },
              }}
            >
              Delete
            </Button>
          </Authenticate>
        }
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
      />
      <DmModal open={openModal} closeModal={setOpenModal}>
        <ConfirmModal
          title={
            selectedRows.length > 1
              ? `Are you sure want to delete ${
                  selectedRows.length
                } stores cause it will also delete ${selectedRows.reduce(
                  (prev, current) => {
                    return prev + current.totalDeals;
                  },
                  0
                )} deals?`
              : `Are you sure you want to delete ${activeRow.name} store  cause it will also delete ${activeRow.totalDeals} deals?`
          }
          subTitle="This action cannot be undone"
          btnConfirmText={"Delete"}
          btnCancelText="Cancel"
          onCancel={setOpenModal}
          onConfirm={onDelete}
        />
      </DmModal>
    </DmBox>
  );
};
